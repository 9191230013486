import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/usr/src/app/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "errors"
    }}>{`Errors`}</h1>
    <p>{`Learn more about the error codes of the API and how you can handle them. `}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Http code`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Sample message`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Error explanation`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`400`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Employee id is invalid`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The employeeId does not exist or the user is no longer active. Please verify the specified user.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`401`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unauthorized`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The app credentials are not sufficient. Please ensure to exchange the refresh token with and Access token or verify that the user who authorised the application is active.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`403`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Your application isn't assigned to the appropriate scope, so it can't perform this action`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Please verify that your application has the right scopes in order to make this request.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`404`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Employee with given id doesn't exist`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`This user does not exist. Please verify employeeId and try again.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`409`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Conflict`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A validation error occurred in your request data.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`429`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Too many requests`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`You’ve passed the rate limit for the allowed number of API requests.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`500`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Server error`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Internal server error - Please inform our API support team`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      